@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');

h1, h2, h3, p {
    padding: 0;
    margin: 0;
}

html {
    font-family: "Roboto Slab", serif;
    background-color: #01073f;
}