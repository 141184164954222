.card {
    margin: 30px;
    border-radius: 5px;
    box-shadow: #000 2px 2px 2px;
    background-color: #f5f5f5;
    padding: 40px;
}

.lead { font-weight: 300; }

.r-2col {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.r-2col > div {
    padding: 5px;
}

.card h3,h4,p {
    padding-bottom: 10px;
}

a {
    color: #A42;
    display: block;
}

.flex-justify {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.align-bottom {

    align-self: flex-end;
}

p > svg, a > svg, span > svg {
    padding-right: 5px;
}